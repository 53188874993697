.footerpage{
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    text-rendering: optimizeLegibility;
    font-family: "Roboto Slab", Helvetica, sans-serif;
    letter-spacing: 0.025em;
    line-height: 1.55em;
    box-sizing: border-box;
    display: block;
    background-color: white ;
    color: #0C4DA2;
    padding: 10px 10px;
    text-align: center;
    font-weight: 300;
    font-size: 11px;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
}
.containerpage{
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    text-rendering: optimizeLegibility;
    font-family: "Roboto Slab", Helvetica, sans-serif;
    letter-spacing: 0.025em;
    line-height: 1.55em;
    color: #0C4DA2;
    text-align: center;
    font-weight: 300;
    font-size: 11px;
    box-sizing: content-box;
    margin-right: auto;
    margin-left: auto;
    width: 1170px;
    padding-right: 0;
    padding-left: 0;
}
.containerpage a{
    color: #0C4DA2;
text-decoration: none;
}

@media screen and (max-width: 768px) {
  
    .footerpage{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
        text-align: center;
        font-size: 13px;
    }
    .containerpage{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        text-align: center;
        font-size: 13px;
    }
    .containerpage a{
        color: #0C4DA2;
    text-decoration: none;
    }
  
  }