.astylyingg{
  text-decoration: none;
  color:black;
  background-color: #0C4DA2;
  color: white;
}
    
.astylyingg:hover{
  background-color: #0C4DA2;
  color: white;
}

.bleubg {
  background-color: #0C4DA2;
}

.bordertoop {
  position: relative;
}

.bordertoop td{
  position: relative;
  word-wrap: normal;
}


.bordertoop td::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 10px;
  background-color: #0C4DA2;
  left: 0;
  top: 0;
}

.btn-customapp{
  font-size: 14px;
  font-weight: bold;
}

.btn-light {
  background-color: #0C4DA2 !important;
  color: #fff !important;
  padding: 15px;
  margin: 0;
}

.button {
  background-color: white;
  color: black !important;
  font-weight: bold;
}

.card-with-transition {
    border: none;
}
/*
.card-with-transition:hover {
transform: scale(1.05);
}
*/
.cardimgg{
  width: 18rem;
}

.center-content {
  display: flex; 
  justify-content: center;
  align-items: center;
}

.col-button {
  margin: 0.5rem;
  color: #0C4DA2 !important;
  margin-top: 2rem;
  background-color: white !important;
  padding: 0.5rem !important; 
  border-radius: 0.5rem !important;
  text-align: center !important;
}

.colorWhite{
  color: #fff;
}

.custom-col {
  padding: 0;
}

.custom-row {
  
  margin-left: 2rem;
  margin-right: 2rem;
}

.custom-modal {
  background-color: transparent !important;
  box-shadow: none !important;
}

.end-content {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.flash {
  font-weight: bold;
  color: #0C4DA2;
}

hr.separatedLine{
  border: 0;
  border-bottom: 1px solid #CED7E0;
  margin: 30px 0;
}

.ImageMarginRight{
  padding-left: 15%;
}

.listing {
  width:100% ;
  table-layout: fixed;
}

.listing thead {
  height: 80px;
}

.listing .bordertoop {
  height: 90px;
  
}

.logo-container2{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.logoLevel {
  width: 100px; /* Adjust the width as per your requirement */
  height: auto;
  margin-right: 10px; /* Adjust the spacing between images */
}

.nopadding{
  padding: 0;
}

.scale-selected {
  transform: scale(1.35);
  transition: transform 0.3s ease-in-out; 
}

.small-icon {
  width: 60px; /* Adjust the width of the small icon */
  height: auto;
}

.table-container22 {
  width:100%;
  position:relative;
}

@media screen and (max-width: 768px) {
  
  .cardimgg {
    width: 18rem;
  }
  .custom-row{
    padding: 0 !important;
  }
  /* .table-responsive {
    overflow-x: scroll !important;
    box-sizing: content-box;
  } */
  .table-responsive {
    
    box-sizing: content-box;
  }
}

