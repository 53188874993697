.logo-col {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .image-col {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  
  .logo-image {
    max-width: 100%;
    max-height: 100px;
    height: auto;
  }
  
  
  
  @media (min-width: 576px) {
    .logo-image {
      max-width: 200px;
    }
  }
  
  @media (min-width: 992px) {
    .logo-image {
      max-width: 300px;
    }
  }
  
  @media (min-width: 1200px) {
    .logo-image {
      max-width: 400px;
    }
  }
  
  
  
  .logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .logo {
    width: 150px; /* Adjust the width according to your logo size */
   
    height: auto;
  }
  
  .slogan {
    margin-top: 10px; /* Adjust the margin according to your desired spacing */
    font-size: 26px; /* Adjust the font size as needed */
    font-weight: bold; /* Adjust the font weight as needed */
        color: white;
  }
  
  .background-image {
    background-color: #0C4DA2;
    background-size: cover;
        color: white;
    background-position: center;
    /* Adjust any additional background properties as needed */
  }
  