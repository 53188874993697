html,body {
  position: relative;
  height: 100%;
}

body {
  width: 100%;
  overflow-x: hidden;
}

#root {
  min-height: 100%;
  display: block;
  position: relative;
  padding-bottom: 53px;

}

ul {
  padding: 0;
  margin: 0;
}

li a {
  text-decoration: none;
}
